<template>
  <div
    class="fixbg"
    :style="{backgroundImage: `url(${src})`}"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.fixbg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  // overflow: hidden;
}
</style>