<template>
  <div class="envItem">
    <ScaleImage>
      <PlaceholderImage
        :width="800"
        :height="450"
        :src="data.imgUrl"
        :alt="data.title || ''"
      />
      <MaskBox
        color="rgba(0,0,0,.6)"
        class="maskBox"
      >
        <span class="icon">
          <img
            :src="require('@/assets/ShuJiuXiang/w-big@2x.png')"
            alt=""
          /></span>
      </MaskBox>
    </ScaleImage>
  </div>
</template>

<script>
import { Scale, Placeholder } from '@/components/Image';
import MaskBox from '@/components/Mask';
export default {
  components: {
    ScaleImage: Scale,
    PlaceholderImage: Placeholder,
    MaskBox,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/ShuJiuXiang/theme.scss';
@import '@/styles/mixin.scss';
.envItem {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  .icon {
    background: $bg-color-1;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
      width: 18px;
      height: 18px;
    }
  }
  @media screen and (min-width: $hover-point) {
    .maskBox {
      transition: 400ms;
      @include hide;
    }
    &:hover {
      .maskBox {
        @include show;
      }
    }
  }
  @media screen and (max-width: $res-point-6) {
    .maskBox {
      left: auto;
      bottom: 10px;
      background: none !important;
      top: auto;
      right: 10px;
    }
    .icon {
      width: 36px;
      height: 36px;
      > img {
        width: 12px;
        height: 12px;
      }
    }
  }
  @media screen and (max-width: $res-point-8) {
    box-shadow: $card-shadow-1;
    border-radius: 6px;
  }
}
</style>