<template>
  <div
    class="placeholderImage el-image"
    :style="{ paddingBottom: `${height / width * 100}%`}"
  >
    <img
      :src="src"
      :alt="alt"
      class="el-image__inner"
    />
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
.placeholderImage {
  display: block;
  overflow: hidden;
  position: relative;
  background: #fff;
  .el-image__inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>