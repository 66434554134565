<template>
  <el-dialog
    title=""
    :visible.sync="visible"
    width="100%"
    :before-close="handleClose"
    :fullscreen="true"
    :append-to-body="true"
    class="viewBigPics"
    :destroy-on-close="true"
  >
    <el-carousel
      v-if="data.length"
      :interval="5000"
      arrow="always"
      class="VBPCarousel"
      height="100%"
      :autoplay="false"
      indicator-position="none"
    >
      <el-carousel-item
        v-for="(item, index) in data"
        :key="index"
      >
        <img
          :src="item"
          alt=""
          class="img"
        />
      </el-carousel-item>
    </el-carousel>
    <div class="txts">
      <p class="title">{{ info.title }}</p>
      <p
        class="desc"
        v-html="info.desc"
      ></p>
    </div>
  </el-dialog>

</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    visible: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    handleClose() {
      this.$emit('onClose');
    },
  },
  computed: {
    ...mapState(['isMobile', 'visibleWH']),
  },
};
</script>

<style lang="scss" scoped>
.viewBigPics {
  ::v-deep {
    .el-dialog {
      background: none;
      box-shadow: none;
      border-radius: 0;
      display: flex;
      flex-direction: column;
    }
    .el-dialog__body,
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      flex: 1;
      overflow: hidden;
      overflow-y: auto;
    }
    .el-dialog__headerbtn {
      z-index: 10;
      right: 16px;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: rgba(31, 45, 61, 0.11);
      font-size: 12px;
      text-align: center;
      line-height: 36px;
      transition: 300ms;
      .el-dialog__close {
        color: #fff;
      }
      &:hover {
        background-color: rgba(31, 45, 61, 0.23);
      }
    }
  }
  .txts {
    color: #fff;
    text-align: center;
    width: 700px;
    margin: 0 auto;
    max-width: 100%;
    padding: 30px 0 50px;
    .title {
      font-size: 22px;
      font-weight: bold;
    }
    .desc {
      line-height: 20px;
      margin-top: 15px;
    }
  }
}
.VBPCarousel {
  height: 80%;
  ::v-deep {
    .el-carousel__item {
      padding-top: 5%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .img {
    max-width: 90%;
    max-height: 100%;
  }
}
</style>