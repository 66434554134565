<template>
  <div
    class="maskLayer"
    :style="{ background: color }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    color: String,
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
.maskLayer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $mask-color-1;
}
</style>