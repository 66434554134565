<template>
  <div class="content">
    <div v-loading="loading">
      <div
        class="list"
        v-if="listData.length"
      >
        <EnvItem
          v-for="item in listData"
          :data="item"
          :key="item.id"
          class="item"
          @click.native="goDetail(item)"
        />
      </div>
      <el-empty v-else />
    </div>
  </div>
</template>

<script>
import EnvItem from '../components/Card/Env.vue';
import { getEnv } from '@/api/ShuJiuXiang/brand';
export default {
  components: {
    EnvItem,
  },
  data() {
    return {
      listData: [],
      loading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    goDetail({ id }) {
      this.$router.push(`/sjx/brand/env/detail/${id}`);
    },
    async getData() {
      this.loading = true;
      try {
        const res = await getEnv();
        this.loading = false;
        this.listData = res.map((d) => ({
          id: d.storeId,
          title: d.storeTitle,
          imgUrl: d.storeImage,
        }));
      } catch (err) {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/ShuJiuXiang/theme.scss';
.content {
  overflow: hidden;
  padding: math.percentage(math.div(168 * 0.8, 1920)) math.percentage(math.div(130, 1920))
    math.percentage(math.div((273 - 100) * 0.8, 1920));
}
.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .item {
    width: math.percentage(math.div(800, 1660));
    margin-bottom: math.percentage(math.div(100, 1660));
  }
}
@media screen and (max-width: $res-point-6) {
  .content {
    padding-left: 5%;
    padding-right: 5%;
  }
}
@media screen and (max-width: $res-point-8) {
  .list {
    .item {
      width: 100%;
      margin-bottom: 15px;
    }
  }
}
</style>